import { graphql} from "gatsby";
import React, { Fragment } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { Hero } from "../components/Hero/Hero";
import { Card } from "../components/Card/Card";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;




const Homepage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t("homepage.hero.title")}
      description={t("homepage.hero.description")}
    >
      <Hero
        title={
          <Fragment>
            {t("homepage.welcome")} <strong>t AG</strong> International<br></br>Holding
          </Fragment>
        }
        description={t("homepage.hero.description")}
        image="homepage/hero.jpg"
        btnLink="/contact/"
        btnText={t("global.interested")}
        homepage
      />
     <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12" custom_class="no-gutter@lg">
          <Card
            title={t("homepage.innovative-solutions")}
            description={
              <Fragment>
                <p>
                  {t("homepage.innovative-solutions-description.text1")}
                </p>
                <p>
                {t("homepage.innovative-solutions-description.text2")}
                </p>
              </Fragment>
            }
            image="homepage/innovative-solutions.jpg"
          />

          <Card
            title={t("homepage.start-up-investment.title")}
            description={
              <Fragment>
                <p>
                {t("homepage.start-up-investment.text1")}
                </p>
                <p>
                {t("homepage.start-up-investment.text2")}
                </p>
              </Fragment>
            }
            image="homepage/start-up-firms-investment.jpg"
            reverse
          />
        </Column>
      </Container>
      <Hero
        inner
        reverse
        title={t("homepage.projects.title")}
        description={
          <Fragment>
            <p>{t("homepage.projects.text1")}</p>
            <p>{t("homepage.projects.text2")}</p>
          </Fragment>
        }
        image="homepage/projects-it-fintech.jpg"
        btnLink="/investments"
        btnText={t("global.our-portfolio")}
      />

    </Layout>
  );
};

export default Homepage;
